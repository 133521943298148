

























import {
  DisplayTotal,
  FormHeader,
  FormPayment,
  FormTax,
} from "@/components/ArPrepayment";
import { useArPrepayment } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import {
  State as ArPrepaymentStore,
  FormValue,
} from "@/store/aRPrepayment.store";
import { InvoicePrepaymentCreateDto } from "@interface/ar-prepayment";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  components: {
    DisplayTotal,
    FormHeader,
    FormPayment,
    FormTax,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  @Ref("formHeader")
  formHeader!: any;

  @Ref("formTax")
  formTax!: any;

  @Ref("formPayment")
  formPayment!: any;

  store!: ArPrepaymentStore;

  loading = false;

  get totalProps() {
    return {
      subTotal: this.store.form.subTotal.toNumber(),
      baseAmount: this.store.form.baseAmount.toNumber(),
      totalTax: this.store.form.totalTax.toNumber(),
      total: this.store.form.total.toNumber(),
    };
  }

  handleBack(): void {
    this.$router.push({
      name: "account-receivable.invoice.prepayment",
      query: this.$route.query,
    });
  }

  handleSubmit(): void {
    this.validateForm();
  }

  handleCreate(form: FormValue): void {
    const { mapFormToCreateDto, create } = useArPrepayment();
    const req: InvoicePrepaymentCreateDto = mapFormToCreateDto(form);

    this.loading = true;
    create(req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_create_success", { documentNumber });
        this.handleBack();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async validateForm(): Promise<void> {
    const headerModel: FormModel = this.formHeader.formModel;
    const taxModel: FormModel = this.formTax.formModel;
    const paymentModel: FormModel = this.formPayment.formModel;
    try {
      await headerModel.validate();
      await taxModel.validate();
      await paymentModel.validate();

      this.handleCreate(this.store.form);
    } catch (error) {
      this.showNotifWarning("notif_validation_error");
    }
  }
}

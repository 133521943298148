var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_ar_prepayment_create") } },
    [
      _c("FormHeader", { ref: "formHeader" }),
      _c("FormTax", { ref: "formTax" }),
      _c("FormPayment", { ref: "formPayment" }),
      _c("a-divider"),
      _c("DisplayTotal", _vm._b({}, "DisplayTotal", _vm.totalProps, false)),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              _vm.$can("create", "invoice-prepayment")
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }